import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import PrivacyView from '@/views/PrivacyView.vue'
import HotPursuit from '@/views/HotPursuitView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/hotpursuit',
    name: 'hotpursuit',
    component: HotPursuit
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView,
  }
]

const router = new VueRouter({
  routes
})

export default router
