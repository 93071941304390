<template>
  <div>
    <b-img :src="require('@/assets/Presentation.png')" fluid alt="Responsive image"></b-img>
    <p></p>
  </div>
</template>

<script>
  export default {
    name: 'HotPursuitBackGround',
    data() {
      return {
        hidden: false,
        };
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll() {
        if (window.pageYOffset > 0) {
          this.hidden = true;
        } else {
          this.hidden = false;
        }
      },
    },
  };
</script>

<style>
  .disappear {
    display: none;
  }
</style>