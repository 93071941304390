<template>
  <HotPursuit msg="This is about HotPursuit Game"/>
</template>

<script>
// @ is an alias to /src
import HotPursuit from '@/components/HotPursuitGame.vue'

export default {
  name: 'HotPursuitView',
  components: {
    HotPursuit
  }
}
</script>